import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators';
import '@iconify/iconify';
import {DateTime} from 'luxon';

@customElement('current-time')
export class CurrentTime extends LitElement {
  render() {
    return html`
      <div class="place-content-center">
        <div class="text-lg text-bg text-center">
          ${DateTime.now().toFormat('HH:mm')}
        </div>
        <div class="text-center text-sm">
          ${DateTime.now().toFormat('ccc dd.MM.')}
        </div>
      </div>
    `;
  }

  createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'current-time': CurrentTime;
  }
}
